// @flow
import * as React from 'react';


type Props = {|
  +children: React.Node,
|};

const TemplateWrapper = (props: Props) => {
  const [recaptchaToken, setRecaptchaToken] = React.useState<?string>(null);

  return props.children;
};

export default TemplateWrapper;
